
import Workgroup from "./../../api/workgroup";
const workgroup = new Workgroup();
import * as tus from "tus-js-client";
export const state = {
    workgroups : [],
    workgroup : null,
    item : null,
    items : [],
    loading : false,
    totalLoad : 10,
    totalItem : 0,
    limit : 100,
    // limit : 10,
    offset : 0,
    keyword : "",
    showFile : false,
    shared : null,
    showProperties : false,
    title : "",
    description : "",
    participants : [],
    showParticipants : false,
    selectedParticipant : null,
    parent_id : "",
    breadcumbs : [],
    hashMove : "",
    showBreadcumb : true,
    recentList : [],
    resetWg : null,
    selected : null,
    newName : '',
    filteredParticipant : [],
    statusSuspendWorkgroup : false,
    publicShare : false
}

export const mutations = {
    SET_PUBLIC_SHARE(state, newValue) {
        state.publicShare = newValue;
    },
    SET_SELECTED(state, value) {
        state.selected = value
    },
    SET_ITEMS(state, newValue) {
        state.items = newValue;
    },
    SET_ITEM(state, newValue) {
        state.item = newValue;
    },
    SET_WORKGROUPS(state, newValue) {
        state.workgroups = newValue;
    },
    SET_WORKGROUP(state, newValue) {
        state.workgroup = newValue;
    },
    SET_SHARED(state, newValue) {
        state.shared = newValue;
    },
    SET_LOADING(state, newValue) {
        state.loading = newValue;
    },
    SET_LIMIT(state, newValue) {
        state.limit = newValue;
    },
    SET_OFFSET(state, newValue) {
        state.offset = newValue;
    },
    SET_TOTALLOAD(state, newValue) {
        state.totalLoad = newValue;
    },
    SET_TOTALITEM(state, newValue) {
        state.totalItem = newValue;
    },
    SET_KEYWORD(state, newValue) {
        state.keyword = newValue;
    },
    SET_SHOWFILE(state, newValue) {
        state.showFile = newValue;
    },
    SET_SHOW_PROPERTIES(state,value){
        state.showProperties = value;
    },
    SET_TITLE(state,newValue){
        state.title = newValue;
    },
    SET_DESCRIPTION(state,newValue){
        state.description = newValue;
    },
    SET_PARTICIPANTS(state,newValue){
        state.description = newValue;
    },
    SET_SHOW_PARTICIPANTS(state,newValue){
        state.showParticipants = newValue;
    },
    SET_SELECTED_PARTICIPANT(state,newValue){
        state.selectedParticipant = newValue;
    },
    SET_PARENT_ID(state, newValue) {
        state.parent_id = newValue;
    },
    SET_HASHMOVE(state, newValue) {
        state.hashMove = newValue;
    },
    DELETE_BREADCUMB(state, newValue) {
        state.breadcumbs = newValue;
    },
    SET_BREADCUMB(state, newValue) {
        // let indexData = state.breadcumbs.findIndex((el)=>{
        //     return el._id == newValue._id
        // });
        // if(indexData < 0){
        //     state.breadcumbs.push(newValue);
        // }else{
        //     state.breadcumbs = state.breadcumbs.slice(0,indexData+1);
        // }
        state.breadcumbs = newValue
    },
    SET_SHOWBREADCUMB(state, newValue) {
        state.showBreadcumb = newValue;
    },
    SET_RECENTLIST(state, newValue) {
        state.recentList = newValue;
    },
    SET_RENAME(state,newValue){
        state.items[newValue].isEdit = !state.items[newValue].isEdit 
    },
    SET_RENAME_ITEM(state,newValue){
        state.newName = newValue
    },
    RESET_WG(state,value){
        state.workgroup = value
    },
    SET_FILTERED_PARTICIPANT(state,value){
        state.filteredParticipant = value
    },
    SET_WORKGROUP_SUSPEND(state,value){
        state.statusSuspendWorkgroup = value
    }
}

export const getters = {
    publicShare(state){
        return state.publicShare
    },
    statusSuspendWorkgroup(state){
        return state.statusSuspendWorkgroup
    },
    filteredParticipant(state){
        return state.filteredParticipant
    },
    newName(state) {
        return state.newName
    },
    selected(state) {
        return state.selected
    },
    resetWG(state) {
        return state.resetWg
    },
    items(state) {
        return state.items
    },
    item(state) {
        return state.item
    },
    loading(state) {
        return state.loading
    },
    workgroups(state) {
        return state.workgroups
    },
    workgroup(state) {
        return state.workgroup
    },
    limit(state) {
        return state.limit
    },
    totalLoad(state) {
        return state.totalLoad
    },
    totalItem(state) {
        return state.totalItem
    },
    offset(state) {
        return state.offset
    },
    keyword(state) {
        return state.keyword
    },
    showFile(state) {
        return state.showFile
    },
    shared(state) {
        return state.shared
    },
    showProperties(state){
        return state.showProperties
    },
    title(state){
        return state.title;
    },
    description(state){
        return state.description;
    },
    participants(state){
        return state.participants;
    },
    showParticipants(state){
        return state.showParticipants;
    },
    selectedParticipant(state){
        return state.selectedParticipant;
    },
    parent_id(state) {
        return state.parent_id
    },
    breadcumbs(state) {
        return state.breadcumbs
    },
    hashMove(state) {
        return state.hashMove
    },
    showBreadcumb(state) {
        return state.showBreadcumb
    },
    recentList(state) {
        return state.recentList
    },
}


export const actions = {
    // Logs in the current user.
    // eslint-disable-next-line no-unused-vars
    joinWg(context,data) {
        context.commit("SET_LOADING",true);
        return new Promise((resolve,reject)=>{
            workgroup.joinWorkgroup(data)
            .then((result)=>{
                context.commit("SET_LOADING",false)
                resolve(result)
            })
            .catch((err)=>{
                context.commit("SET_LOADING",false)
                reject(err)
            })
        })
    },
    fileretrieve(context,data) {
        // context.commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            workgroup.fileRetrieve(data)
            .then((result)=>{              
                // context.commit("SET_LOADING",false);
                resolve(result);
            })
            .catch((err)=>{
                // context.commit("SET_LOADING",false);
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            });
        });
    },
    create(context,data) {
        context.commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            workgroup.create(data)
            .then((result)=>{              
                context.commit("SET_LOADING",false);
                if(result.data._id != undefined){
                    context.dispatch("retrieve",result.data._id);
                }else{
                    context.dispatch("retrieve",result.data[0]._id);
                }
                context.dispatch("workgroupList");
                context.dispatch("chat/newGroup",{
                    group: result.data._id != undefined ? result.data._id : result.data[0]._id,
                    user : context.rootGetters["user/id"]
                },{root:true});
                context.dispatch("showDetails");
                resolve(result);
            })
            .catch((err)=>{
                context.commit("SET_LOADING",false);
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            });
        });
    },
    sendFilesToChat(context, data) {
        return new Promise((resolve, reject) => {
            workgroup.sendFileToChat(data)
            .then((result) => {
                resolve(result)
            })
            .catch((err) => {
                reject(err.response.statusText)
            })
        })
    },
    // eslint-disable-next-line no-unused-vars
    workgroupList(context) {
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            workgroup.listing(context.getters['limit'],context.getters['offset'])
            .then((result)=>{
                context.commit("SET_WORKGROUPS",result.reverse());
                localStorage.setItem('workgroupList', JSON.stringify(result.reverse()))
                resolve(result);
            })
            .catch((err)=>{
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            });
        });
    },

    // eslint-disable-next-line no-unused-vars
    updateWorkgroup(context,data) {
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            workgroup.updateWorkgroup(data)
            .then((result)=>{
                context.dispatch("retrieve",data.id);
                resolve(result);
            })
            .catch((err)=>{
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            });
        });
    },

    // eslint-disable-next-line no-unused-vars
    changeAccess(context,data) {
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            workgroup.changeAccess(data)
            .then((result)=>{
                context.dispatch("retrieve",data.id);
                resolve(result);
            })
            .catch((err)=>{
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            });
        });
    },
    // eslint-disable-next-line no-unused-vars
    listing(context,deleted="") {
        const { commit, getters } = context;
        var item = context.getters["workgroup"];
        var currentUser = context.rootGetters["user/id"]
        commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        
        if(deleted != "noclear"){
            context.commit("storage/SET_ALL_SELECTED",[],{root:true});
        }else{
            deleted = ""
        }
        return new Promise((resolve, reject) => {
            workgroup.listingFiles(
                item._id,
                getters["limit"],
                getters["offset"],
                getters["keyword"],
                getters["parent_id"],
                deleted
            )
            .then((result)=>{
                var data = result.data
                if(item.type == 'PRIVATE'){
                    var newData = data.map((v) => {
                        return {
                            ...v,
                            isLock : currentUser == v.added_by || currentUser == item.owner_id ? false : true
                        }
                    })
                    var sortNewData = newData.sort(function(x,y){return (x === y)? 0 : x.isLock? 1 : -1})
                    commit('SET_ITEMS', sortNewData)
                } else {
                    commit('SET_ITEMS', result.data);
                }
                commit('SET_TOTALLOAD', result.totalLoad);
                commit('SET_TOTALITEM', result.totalData);
                commit("SET_LOADING",false);
                context.commit("SET_BREADCUMB", result.breadcumbs)
                context.dispatch("user/getLimit",null,{root:true});
                resolve(result);
            })
            .catch((err)=>{
                commit('SET_TOTALLOAD', 0);
                commit('SET_TOTALITEM', 0);
                commit('SET_ITEMS', []);
                commit("SET_LOADING",false);
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            });
        });
    },
    rename(context,value) {
        const { commit,dispatch } = context;
        var nameItem = context.getters["newName"]
        var data = {
            id: value._id,
            file_name: value.file_name
        }
        var item = context.getters["workgroup"];
        var newData = Object.assign(data,{workgroup_id:item._id})
        commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            workgroup.rename(
                newData
            )
            .then((result)=>{
                commit("SET_LOADING",false);
                dispatch("listing");
                context.dispatch("user/getLimit",null,{root:true});
                resolve(result);
            })
            .catch((err)=>{
                commit("SET_LOADING",false);
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            });
        });
    },
    recent(context) {
        const { commit } = context;
        var item = context.getters["workgroup"];
        commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        context.rootGetters["storage/displaySidebar"];
        return new Promise((resolve, reject) => {
            workgroup.getRecent(
                item._id)
            .then((result)=>{
                commit("SET_LOADING",false);
                commit("SET_RECENTLIST",result);
                context.dispatch("user/getLimit",null,{root:true});
                resolve(result);
            })
            .catch((err)=>{
                commit("SET_LOADING",false);
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            });
        });
    },
    back(context){
        const { commit, getters,dispatch } = context;
        var breadCumbs = getters["breadcumbs"];
        if(breadCumbs.length > 1){
            commit("SET_PARENT_ID",breadCumbs[breadCumbs.length - 2]._id);
            // commit("SET_BREADCUMB",breadCumbs[breadCumbs.length-2]);
            commit("SET_KEYWORD","");
            dispatch("listing");
        }else{
            commit("DELETE_BREADCUMB",[]);
            commit("SET_PARENT_ID","");
            commit("SET_KEYWORD","");
            dispatch("listing");
        }
    },
    // eslint-disable-next-line no-unused-vars
    copyToWG(context, data) {
        var workgroupDestination = context.getters["workgroup"];
        var payload = {
            id: data.id,
            parent_id: data.parent_id,
            workgroup_id: workgroupDestination._id
        }
        return new Promise((resolve, reject) => {
            workgroup.copyToWorkgroup(payload)
            .then((result) => {
                resolve(result)
            })
            .catch((err) => {
                reject(err.data.display_message)
            })
        })
    },
    copyWgToPersonal(context, data) {
        var workgroupDestination = context.getters["workgroup"];
        var payload = {
            id: data.id,
            parent_id: data.parent_id,
            workgroup_id: workgroupDestination._id
        }
        return new Promise((resolve, reject) => {
            workgroup.copyToPersonal(payload)
            .then((result) => {
                resolve(result)
            })
            .catch((err) => {
                reject(err)
            })
        })
    },
    retrieve(context,id) {
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        // context.commit("RESET_WG", [])
        return new Promise((resolve, reject) => {
            workgroup.retrieve(id)
            .then((result)=>{
                var listParticipant = result.participant
                var index = listParticipant.findIndex((v) => {
                    return v._id == result.owner_id
                })
                if(index < 0) {
                    var obj = {
                        access_type: 'Owner',
                        email: result.email,
                        firts_name: result.name,
                        lastname: '',
                        _id: result.owner_id,
                        status: 3
                    }
                    listParticipant.push(obj)
                }
                result.participant = listParticipant
                var mainpages = document.getElementById("content-pages");
                context.commit("SET_WORKGROUP",result);
                if(result.status == false){
                    mainpages.style.marginRight = '0px'
                    context.commit("SET_WORKGROUP_SUSPEND", true)
                } else {
                    context.commit("SET_WORKGROUP_SUSPEND", false)
                }
                context.commit("state/SET_STATE",'workgroup',{root:true});
                context.dispatch("workgroupList");
                context.dispatch("listing");
                context.dispatch("filteredParticipant", listParticipant)
                context.dispatch("chat/sortingParticipant",null,{root:true});
                context.dispatch("recent");
                resolve(result);
            })
            .catch((err)=>{
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            });
        });
    },
    // eslint-disable-next-line no-unused-vars
    showDetails(context){
        context.commit("state/SET_STATE","workgroup",{root:true});
        context.commit("SET_SHOW_PARTICIPANTS",false);
        context.commit("storage/SET_SHOW_PROPERTIES",false,{root:true});
        // var header = document.getElementById("page-topbar");
        // var jarak = document.getElementById("jarak");
        // jarak.style.display = "block";
        // header.style.right = "400px"; 
        // var mainpage = document.getElementById("main-page");
        // mainpage.style.marginRight = "410px"; 
    },
    // eslint-disable-next-line no-unused-vars
    hideDetail(context){
        var jarak = document.getElementById("jarak");
        jarak.style.display = "none";
        context.commit("SET_SHOW_PARTICIPANTS",false);
        // var header = document.getElementById("page-topbar");
        // header.style.right = "0"; 
        // var mainpage = document.getElementById("main-page");
        // mainpage.style.marginRight = "10px"; 
    },
    // eslint-disable-next-line no-unused-vars
    resendInvite(context,data) {
        context.commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            workgroup.resendInvitation(data)
            .then((result)=>{
                context.commit("SET_LOADING",false);
                context.dispatch("retrieve",data.workgroup_id);
                resolve(result);
            })
            .catch((err)=>{
                context.commit("SET_LOADING",false);
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            });
        });
    }, 
    removeParticipant(context,data) {
        context.commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            workgroup.removeParticipant(data)
            .then((result)=>{
                context.commit("SET_LOADING",false);
                context.dispatch("retrieve",data.workgroup_id);
                resolve(result);
            })
            .catch((err)=>{
                context.commit("SET_LOADING",false);
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            });
        });
    }, 
    beforeUploaded(context, data) {
        const { commit,dispatch } = context;
        commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            workgroup.beforeUpload(
                data
            )
            .then((result)=>{
                commit("SET_LOADING",false);
                dispatch("listing");
                context.dispatch("user/getLimit",null,{root:true});
                resolve(result);
            })
            .catch((err)=>{
                commit("SET_LOADING",false);
                // context.commit("error/SET_MESSAGE",err,{root:true});
                // context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            });
        });
    },
    upload(context,data) {
        const { commit,dispatch } = context;
        commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            workgroup.upload(
                data
            )
            .then((result)=>{
                commit("SET_LOADING",false);
                dispatch("listing");
                context.dispatch("user/getLimit",null,{root:true});
                resolve(result);
            })
            .catch((err)=>{
                commit("SET_LOADING",false);
                // context.commit("error/SET_MESSAGE",err,{root:true});
                // context.commit("error/SET_ERROR",true,{root:true});
                reject(err.response);
            });
        });
    },
    uploadFolder(context,data) {
        const { commit,dispatch } = context;
        commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            workgroup.uploadFolder(
                data
            )
            .then((result)=>{
                commit("SET_LOADING",false);
                dispatch("listing");
                context.dispatch("user/getLimit",null,{root:true});
                resolve(result);
            })
            .catch((err)=>{
                commit("SET_LOADING",false);
                // context.commit("error/SET_MESSAGE",err,{root:true});
                // context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            });
        });
    },
    uploadTus(context,data){
        const {file,metadata,filesData,isFolder,dest} = data;
        return new Promise((resolve,reject)=>{
            // Create a new tus upload
            var upload = new tus.Upload(file, {
                endpoint: process.env.VUE_APP_TUS,
                retryDelays: [0, 3000, 5000, 10000, 20000],
                chunkSize : 1000000,
                metadata: metadata,
                // eslint-disable-next-line no-unused-vars
                onError: function(error) {
                    reject(error)
                    context.commit("upload/STATUS",{id:filesData.id,status:4,error:"Upload Failed"},{root:true});
                },
                onProgress: function(bytesUploaded, bytesTotal) {
                    var percentage = (bytesUploaded / bytesTotal * 100).toFixed(2)
                    context.commit("upload/ADD_PERCENTAGE",{id:filesData.id,percentage:parseInt(percentage)},{root:true});
                    context.commit("upload/STATUS",{id:filesData.id,status:2,error:""},{root:true});
                },
                onSuccess: function() {
                    context.commit("upload/STATUS",{id:filesData.id,status:3,error:""},{root:true});
                    if(isFolder){
                        resolve({
                            hash_name : upload.url.split('/').pop(),
                            file_name : file.name,
                            extension : file.name.split('.').pop() == file.name ? 'txt' : file.name.split('.').pop(),
                            size : file.size,
                            path : file.webkitRelativePath
                        });
                        context.commit("upload/UPDATE_UPLOADED",{id:filesData.id},{root:true});
                    }else{
                        var item = context.getters["workgroup"]
                        var payload = {
                            workgroup_id: item._id,
                            parent_id: dest,
                            hash_name: upload.url.split('/').pop(),
                            file_name: file.name,
                            extension: file.name.split('.').pop() == file.name ? 'txt' : file.name.split('.').pop(),
                            size: file.size
                        }
                        
                        context.dispatch("upload",payload)
                        .then((v)=>{
                            if(!isFolder){
                                context.commit("upload/STATUS",{id:filesData.id,status:3,error:v},{root:true});
                                context.commit("upload/DONE",{id:filesData.id,done:true},{root:true});
                            }
                        })
                        .catch((e)=>{
                            e.data.data = e.data.display_message
                            context.commit("upload/DONE",{id:filesData.id,done:true},{root:true});
                            context.commit("upload/STATUS",{id:filesData.id,status:4,error:e},{root:true});
                        });
                        resolve({
                            hash_name : upload.url.split('/').pop(),
                            file_name : file.name,
                            extension : file.name.split('.').pop() == file.name ? 'txt' : file.name.split('.').pop(),
                            size : file.size
                        });
                    }
                }
                
            });
            // Check if there are any previous uploads to continue.
            upload.findPreviousUploads().then(function (previousUploads) {
                // Found previous uploads so we select the first one. 
                if (previousUploads.length) {
                    upload.resumeFromPreviousUpload(previousUploads[0])
                }
                // Start the upload
                upload.start()
            })  
        })
    },
    createFile(context,data) {
        const { commit,dispatch } = context;
        var item = context.getters["workgroup"];
        // var newData = Object.assign(data,{workgroup_id:item._id,owner_id:item.owner_id})
        var newData = Object.assign(data,{workgroup_id:item._id})
        commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            workgroup.createFile(
                newData
            )
            .then((result)=>{
                commit("SET_LOADING",false);
                dispatch("listing");
                context.dispatch("user/getLimit",null,{root:true});
                resolve(result);
            })
            .catch((err)=>{
                commit("SET_LOADING",false);
                context.commit("error/SET_MESSAGE",err.data.display_message,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            });
        });
    },
    newFolder(context,data) {
        const { commit } = context;
        var item = context.getters["workgroup"];
        var newData = Object.assign(data,{workgroup_id:item._id,name:data.folder_name})
        // var newData = Object.assign(data,{workgroup_id:item._id,owner_id:item.owner_id,name:data.folder_name})
        commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            workgroup.newFolder(
                newData
            )
            .then((result)=>{
                commit("SET_LOADING",false);
                // dispatch("listing");
                context.dispatch("user/getLimit",null,{root:true});
                resolve(result);
            })
            .catch((err)=>{
                commit("SET_LOADING",false);
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            });
        });
    },
    move(context,data) {
        const { commit,dispatch,getters } = context;
        var wg = context.getters["workgroup"];
        var payload = {
            target_id: data.target_id,
            destination_id: data.destination_id,
            workgroup_id: wg._id
        }

        // var breadCumbs = getters["breadcumbs"];
        // var formData = new FormData();
        // formData.append("src_hash",id);
        // formData.append("dest_hash", breadCumbs.length > 0 ? breadCumbs[breadCumbs.length-1].hash_name : "");
        // var item = context.getters["workgroup"];
        // formData.append("workgroup_id",item._id);
        // formData.append("owner_id",item.owner_id);

        commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            workgroup.move(
                payload
            )
            .then((result)=>{
                commit("SET_LOADING",false);
                dispatch("listing");
                context.dispatch("user/getLimit",null,{root:true});
                resolve(result);
            })
            .catch((err)=>{
                commit("SET_LOADING",false);
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            });
        });
    },
    moveFolder(context,id) {
        const { commit,dispatch,getters } = context;
        var breadCumbs = getters["breadcumbs"];
        var item = context.getters["workgroup"];
        var param = {
            target_id : id,
            destination_id : breadCumbs.length > 0 ? breadCumbs[breadCumbs.length-1]._id : "",
            workgroup_id : item._id
        }
        commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            workgroup.moveFolder(
                param
            )
            .then((result)=>{
                commit("SET_LOADING",false);
                dispatch("listing");
                context.dispatch("user/getLimit",null,{root:true});
                resolve(result);
            })
            .catch((err)=>{
                commit("SET_LOADING",false);
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            });
        });
    },
    copy(context,data) {
        const { commit,dispatch,getters } = context;
        var wg = context.getters["workgroup"];
        var payload = {
            target_id: data.target_id,
            destination_id: data.destination_id,
            workgroup_id: wg._id
        }

        // var breadCumbs = getters["breadcumbs"];
        // var formData = new FormData();
        // formData.append("src_hash_name",id);
        // formData.append("dest_hash_name", breadCumbs.length > 0 ? breadCumbs[breadCumbs.length-1].hash_name : "");
        // var item = context.getters["workgroup"];
        // formData.append("workgroup_id",item._id);
        // formData.append("owner_id",item.owner_id);

        commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            workgroup.copy(
                payload
            )
            .then((result)=>{
                commit("SET_LOADING",false);
                dispatch("listing");
                context.dispatch("user/getLimit",null,{root:true});
                resolve(result);
            })
            .catch((err)=>{
                commit("SET_LOADING",false);
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            });
        });
    },
    copyFolder(context,data) {
        const { commit,dispatch,getters } = context;
        // var breadCumbs = getters["breadcumbs"];
        var item = context.getters["workgroup"];
        var param = {
            target_id : data.target_id,
            destination_id : data.destination_id,
            workgroup_id : item._id
        }
        // var param = {
        //     target_id : id,
        //     destination_id : breadCumbs.length > 0 ? breadCumbs[breadCumbs.length-1]._id : "",
        //     workgroup_id : item._id,
        //     owner_id : item.owner_id,
        // }
        commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            workgroup.copyFolder(
                param
            )
            .then((result)=>{
                commit("SET_LOADING",false);
                dispatch("listing");
                context.dispatch("user/getLimit",null,{root:true});
                resolve(result);
            })
            .catch((err)=>{
                commit("SET_LOADING",false);
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            });
        });
    },
    deleteFile(context,id) {
        const { commit,dispatch } = context;
        var item = context.getters["workgroup"];
        commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            workgroup.deleteFile(
                id,
                item._id
            )
            .then((result)=>{
                commit("SET_LOADING",false);
                dispatch("listing");
                context.dispatch("user/getLimit",null,{root:true});
                resolve(result);
            })
            .catch((err)=>{
                commit("SET_LOADING",false);
                context.commit("error/SET_MESSAGE",err.data.display_message,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            });
        });
    },
    leaveWg(context,payload) {
        const { commit,dispatch } = context;
        commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            workgroup.leaveWorkgroup(
                payload
            )
            .then((result)=>{
                commit("SET_LOADING",false);
                dispatch("workgroupList");
                context.dispatch("user/getLimit",null,{root:true});
                resolve(result);
            })
            .catch((err)=>{
                commit("SET_LOADING",false);
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            });
        });
    },
    deleteForever(context,id) {
        const { commit,dispatch } = context;
        commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            workgroup.deleteForever(
                id
            )
            .then((result)=>{
                commit("SET_LOADING",false);
                dispatch("workgroupList");
                context.dispatch("user/getLimit",null,{root:true});
                resolve(result);
            })
            .catch((err)=>{
                commit("SET_LOADING",false);
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            });
        });
    },
    filteredParticipant(context, participantWG){
        var wg = participantWG

        var owner = wg.filter((o) => {
            return o.access_type == 'Owner'
        })
        var accParticipant = wg.filter((v) => {
            return v.status == 3 && v.access_type != "Owner"
        })
        var waitParticipant = wg.filter((v) => {
            return v.status != 3
        })

        var resultFilter = [...owner, ...accParticipant, ...waitParticipant]
        context.commit("SET_FILTERED_PARTICIPANT", resultFilter)
    },
    setFavoriteWg(context,payload) {
        const { commit,dispatch } = context;
        commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            workgroup.setFavorite(
                payload
            )
            .then((result)=>{
                commit("SET_LOADING",false);
                dispatch("workgroupList");
                context.dispatch("user/getLimit",null,{root:true});
                resolve(result);
            })
            .catch((err)=>{
                commit("SET_LOADING",false);
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            });
        });
    },
    unFavoriteWg(context,payload) {
        const { commit,dispatch } = context;
        commit("SET_LOADING",true);
        context.commit("error/SET_MESSAGE","",{root:true});
        context.commit("error/SET_ERROR",false,{root:true});
        context.commit("error/SET_SUCCESS",false,{root:true});
        return new Promise((resolve, reject) => {
            workgroup.unFavorite(
                payload
            )
            .then((result)=>{
                commit("SET_LOADING",false);
                dispatch("workgroupList");
                context.dispatch("user/getLimit",null,{root:true});
                resolve(result);
            })
            .catch((err)=>{
                commit("SET_LOADING",false);
                context.commit("error/SET_MESSAGE",err,{root:true});
                context.commit("error/SET_ERROR",true,{root:true});
                reject(err);
            });
        });
    }
    // recent(context) {
    //     const { commit } = context;
    //     commit("SET_LOADING",true);
    //     context.commit("error/SET_MESSAGE","",{root:true});
    //     context.commit("error/SET_ERROR",false,{root:true});
    //     context.commit("error/SET_SUCCESS",false,{root:true});
    //     return new Promise((resolve, reject) => {
    //         storage.getRecent()
    //         .then((result)=>{
    //             commit("SET_LOADING",false);
    //             commit("SET_RECENTLIST",result);
    //             context.dispatch("user/getLimit",null,{root:true});
    //             resolve(result);
    //         })
    //         .catch((err)=>{
    //             commit("SET_LOADING",false);
    //             context.commit("error/SET_MESSAGE",err,{root:true});
    //             context.commit("error/SET_ERROR",true,{root:true});
    //             reject(err);
    //         });
    //     });
    // },
}