import store from '@/state/store'

export default [
  {
    path: '/',
    name: 'home',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/storage/home'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('./views/account/loginV3'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ name: 'home' })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    component: () => import('./views/utility/maintenanceV3')
  },
  {
    path: '/access-permission',
    name: 'AccessPermission',
    component: () => import('./views/utility/permissionAccess')
  },
  {
    path: '/workgroup-not-found',
    name: 'WorkgroupNotFound',
    component: () => import('./views/utility/workgroupNotFound')
  },
  {
    path: '/blocked',
    name: 'blocked',
    component: () => import('./views/utility/blockedAccount')
  },
  {
    path: '/confirm-auth-code',
    name: 'MultiFactorAuth',
    component: () => import('./views/utility/multiFactorAuth')
  },
  {
    path: '/confirm-backup-code',
    name: 'MultiFactorBackup',
    component: () => import('./views/utility/multiFactorBackupCode')
  },
  {
    path: '/confirm-access-desktop',
    name: 'AccessDesktop',
    component: () => import('./views/utility/openDesktop')
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import('./views/account/registerV3'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ name: 'home' })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/signup/v2',
    name: 'signupV2',
    component: () => import('./views/account/registerV2'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ name: 'home' })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/forgot-password',
    name: 'Forgot password',
    component: () => import('./views/account/forgotPasswordV3'),
    // component: () => import('./views/account/forgot-password'),
    // component: () => import('./views/account/forgot-passwordV3'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ name: 'home' })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/reset-message/:email',
    name: 'Reset message',
    component: () => import('./views/account/forgotPasswordMsgV3'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ name: 'home' })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/change-password',
    name: 'Change password',
    component: () => import('./views/account/changePasswordV3'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ name: 'home' })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/success-change-password',
    name: 'Success change password',
    component: () => import('./views/account/changePasswordSuccessV3'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ name: 'home' })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/verify',
    name: 'Verify',
    component: () => import('./views/account/verifycode'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ name: 'home' })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/resend',
    name: 'Resend',
    component: () => import('./views/account/resend'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ name: 'home' })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  
  {
    path: '/confirmed',
    name: 'Confirmed',
    component: () => import('./views/account/confirmed'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ name: 'home' })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },

  {
    path: '/logout',
    name: 'logout',
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        store.dispatch('auth/logOut')
        const authRequiredOnPreviousRoute = routeFrom.matched.some(
          (route) => route.push('/login')
        )
        // Navigate back to previous page, or home as a fallback
        next(authRequiredOnPreviousRoute ? { name: 'home' } : { ...routeFrom })
      },
    },
  },
  {
    path: '/404',
    name: '404',
    component: require('./views/utility/404V3').default,
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: '*',
    redirect: '404',
  },
  {
    path: '/trash',
    name: 'trash',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/storage/trash'),
  },
  {
    path: '/recent',
    name: 'recent',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/recent/recent'),
  },
  {
    path: '/search',
    name: 'Search',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/storage/search'),
  },
  {
    path: '/settings-workgroup',
    name: 'workgroupSetting',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/settings/settingWorkgroup'),
  },
  {
    path: '/chat',
    name: 'chat',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/chat/chat2'),
  },
  {
    path: '/share',
    name: 'share',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/storage/sharewithme'),
  },
  {
    path: '/shared',
    name: 'shared',
    meta: {
      authRequired: false,
    },
    component: () => import('./views/sharedV3'),
  },
  {
    path: '/editpassword',
    name: 'editpassword',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/account/editpassword'),
  },
  {
    path: '/workgroup',
    name: 'workgroup',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/storage/home'),
  },
  {
    path: '/settings',
    name: 'Setting',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/storage/settingV3'),
  },
  {
    path: '/enterprise/admin/overview',
    name: 'EnterpriseAdminOverview',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/enterprise/overview'),
  },
  {
    path: '/enterprise/admin/member',
    name: 'EnterpriseAdminMember',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/enterprise/member'),
  },
  {
    path: '/enterprise/admin/customize',
    name: 'EnterpriseAdminCustomize',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/enterprise/customization'),
  },
  // {
  //   path: '/enterprise/admin/plan',
  //   name: 'EnterpriseAdminPlan',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('./views/enterprise/plan'),
  // },
  {
    path: '/settings/overview',
    name: 'SettingsOverview',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/storage/settings/overview'),
  },
  // {
  //   path: '/settings/plan',
  //   name: 'SettingsPlan',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('./views/storage/settings/plan'),
  // },
  {
    path: '/workgroup/settings',
    name: 'WorkgroupSettings',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/storage/settings/workgroupSettings'),
  },
  {
    path: '/workgroup/participants',
    name: 'WorkgroupParticipants',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/storage/settings/workgroupParticipants'),
  },
  {
    path: '/shared-file',
    name: 'FileShared',
    meta: {
      authRequired: true,
    },
    component: () => import('../components/widgets/fileShared'),
  },
  // {
  //   path: '/packages',
  //   name: 'Package',
  //   // meta: {
  //   //   authRequired: true,
  //   // },
  //   component: () => import('./views/packages/packageV3'),
  //   // component: () => import('./views/packages/package'),
  // },
  // {
  //   path: '/checkout/:id',
  //   name: 'Checkout',
  //   // meta: {
  //   //   authRequired: true,
  //   // },
  //   component: () => import('./views/packages/checkout'),
  // },
  // {
  //   path: '/payment/histories',
  //   name: 'PaymentHistory',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('../components/paymentHistory'),
  // },
  // {
  //   path: '/payment/success',
  //   name: 'PaymentSuccess',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('./views/packages/paymentSuccess'),
  // },
  // {
  //   path: '/payment/failed',
  //   name: 'PaymentFailed',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('./views/packages/paymentFailed'),
  // },
  // {
  //   path: '/enterprise/payment/success',
  //   name: 'EnterprisePaymentSuccess',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('./views/enterprise/paymentSuccess'),
  // },
  // {
  //   path: '/enterprise/payment/failed',
  //   name: 'EnterprisePaymentFailed',
  //   meta: {
  //     authRequired: true,
  //   },
  //   component: () => import('./views/enterprise/paymentFailed'),
  // },
  {
    path: '/enterprise/validate',
    name: 'EnterpriseValidateToken',
    meta: {
      authRequired: false,
    },
    component: () => import('./views/enterprise/validate'),
  },
]
